import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Flex, Box, Input, Text } from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function PartnerName({ details }) {
  const { question } = details;
  const { id, title, subTitle, description, step, number } = question;
  const [data, setData] = useState({
    id,
    questionNo: number,
    partnerFirstName: '',
    partnerLastName: '',
    errors: '',
    errorMessage: '',
  });

  const handleChange = ({ target }) => {
    setData({
      ...data,
      [target.name]: target.value,
    });
  };

  const handleSubmit = () => {
    if (!data.partnerFirstName && !data.partnerLastName) {
      setData({
        ...data,
        errorMessage: 'Please fill out all fields',
      });
    }

    let userDetails = localStorage.getItem('userDetails');
    userDetails = userDetails ? JSON.parse(userDetails) : {};
    userDetails['partnerFirstName'] = data.partnerFirstName;
    userDetails['partnerLastName'] = data.partnerLastName;
    localStorage.setItem('userDetails', JSON.stringify(userDetails));

    navigate(`/${step}`);
  };

  const updateAnswerFromLocal = () => {
    const localQuestions = getFromLocalStorage('userDetails');
    if (localQuestions) {
      setData({
        ...data,
        partnerFirstName: localQuestions['partnerFirstName'],
        partnerLastName: localQuestions['partnerLastName'],
      });
    }
  };

  useEffect(() => {
    updateAnswerFromLocal();
  }, []);

  return (
    <Flex direction="column">
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        description={description}
        errors={data.errors}
        errorMessage={data.errorMessage}
      />

      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Box mt={4}>
          <Text as="h5" fontWeight="bold">
            First name
          </Text>

          <Input
            type="text"
            id="firstName"
            name="partnerFirstName"
            onChange={handleChange}
            value={data.partnerFirstName}
            required
          />
        </Box>
        <Box mt={2}>
          <Text as="h5" fontWeight="bold">
            Last name
          </Text>

          <Input
            type="text"
            id="lastName"
            name="partnerLastName"
            onChange={handleChange}
            value={data.partnerLastName}
            required
          />
        </Box>
        <Button type="submit" bg="brandGreen" bgHover="brandGreenHover" mt="6">
          Next
        </Button>
      </Box>
    </Flex>
  );
}
