import React, { useState, useEffect } from 'react';
import { Flex, Box, FormLabel, Switch } from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import QuestionHeader from '../partials/QuestionHeader';
import CustomInput from '../inputs/CustomInput';
import Button from '../partials/Button';

export default function WeightSwitcher({ details }) {
  const { question, handleSubmit, errors } = details;
  const {
    id,
    number,
    title,
    subTitle,
    description,
    errorMessage,
    step,
    answers,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: step,
    answer: {},
    st: '',
    llbs: '',
    kg: '',
    unitSwitch: false,
    errors: false,
    question: emailQuestion,
  });

  const handleChange = ({ target }) => {
    setData({
      ...data,
      [target.name]: target.value,
    });
  };

  const setAnswer = () => {
    let updatedAnswer;

    if (data.unitSwitch === true) {
      updatedAnswer = { Height: `${data.kg}Kg` };
    } else {
      updatedAnswer = { Height: `${data.st}St / ${data.llbs}Llbs` };
    }

    setData({
      ...data,
      answer: updatedAnswer,
      unitSwitch: data.unitSwitch,
    });
  };

  useEffect(() => {
    setAnswer();
  }, [data.st, data.llbs, data.kg, data.unitSwitch]);

  useEffect(() => {
    const updateAnswerFromLocal = () => {
      const localQuestions = getFromLocalStorage('questions');

      if (localQuestions) {
        const thisQuestion = localQuestions.filter(
          (q) => q['questionNo'] === number
        );

        thisQuestion.map((qu) => {
          setData({
            ...data,
            answer: qu.answer,
            st: qu.st,
            llbs: qu.llbs,
            kg: qu.kg,
            unitSwitch: qu.unitSwitch,
          });
        });
      }
    };
    updateAnswerFromLocal();
  }, []);

  const toggleUnits = () => {
    setData({
      ...data,
      unitSwitch: !data.unitSwitch,
    });
  };

  return (
    <Flex direction="column">
      {errors && <h5 style={{ color: 'red' }}>{errorMessage}</h5>}
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        description={description}
        errors={data.errors}
        errorMessage={data.errorMessage}
      />
      <Box
        as="form"
        mt={4}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        {answers &&
          answers.map((item, i) => {
            return (
              <Flex key={i} direction="column">
                {data.unitSwitch === false ? (
                  <Flex>
                    <CustomInput
                      name="st"
                      label="st"
                      min="5"
                      max="50"
                      value={data.st}
                      handleChange={handleChange}
                      mr="4"
                      required={data.unitSwitch ? '' : 'required'}
                    />
                    <CustomInput
                      name="llbs"
                      label="llbs"
                      min="0"
                      max="13"
                      value={data.llbs}
                      handleChange={handleChange}
                      required={data.unitSwitch ? '' : 'required'}
                    />
                  </Flex>
                ) : (
                  <>
                    <CustomInput
                      name="kg"
                      label="kg"
                      min="31"
                      max="318"
                      value={data.kg}
                      handleChange={handleChange}
                      required={data.unitSwitch ? 'required' : ''}
                    />
                  </>
                )}
              </Flex>
            );
          })}
        <Flex direction="column" mt={4}>
          <Flex align="center">
            <Switch
              id="toggle-unit"
              mr={2}
              onChange={toggleUnits}
              isChecked={data.unitSwitch}
            />
            <FormLabel htmlFor="toggle-unit">
              Change unit to
              {data.unitSwitch ? ' St/Llbs' : ' Kg'}
            </FormLabel>
          </Flex>
          <Box mt={6} alignSelf="flex-start">
            <Button type="submit" bg="brandGreen" bgHover="brandGreenHover">
              Next
            </Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}
