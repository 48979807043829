import React, { useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Button, Flex } from '@chakra-ui/core';

import CallBack from '../partials/CallBack';
import QuestionHeader from '../partials/QuestionHeader';

export default function Landing({ details }) {
  const { question } = details;
  const {
    title,
    subTitle,
    step,
    hasCallBack,
    description,
    infoModal,
    infoModalContent,
  } = question;

  const [data, setData] = useState({
    modalOpen: false,
  });

  const toggleModal = () => {
    setData({
      modalOpen: !data.modalOpen,
    });
  };

  return (
    <Flex direction="column">
      <CallBack isOpen={data.modalOpen} toggle={toggleModal} />
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        description={description}
        infoModal={infoModal}
        infoModalContent={infoModalContent}
      />

      <Button
        as={GatsbyLink}
        to={`/${step}`}
        bg="brandGreen"
        _hover={{ bg: 'brandGreenHover' }}
        _active={{ bg: 'brandGreenHover' }}
        mt={6}
        alignSelf="flex-start"
        color="#fff"
      >
        Next
      </Button>
      {hasCallBack && (
        <Button
          onClick={toggleModal}
          alignSelf="flex-start"
          mt={6}
          bg="brandOrange"
          _hover={{ bg: 'brandOrangeHover' }}
          _active={{ bg: 'brandOrangeHover' }}
          color="white"
        >
          Request callback
        </Button>
      )}
    </Flex>
  );
}
