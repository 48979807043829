import React, { useState, useEffect, createRef } from 'react';
import { Flex, Box, Input } from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function ChildAges({ details }) {
  const { question, handleSubmit } = details;
  const {
    id,
    number,
    title,
    errorMessage,
    step,
    subTitle,
    description,
    inputs,
    infoModal,
    infoModalContent,
    infoModalInsurance,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: step,
    answer: {},
    errors: false,
    question: emailQuestion,
    errorMessage,
  });

  const handleChange = ({ target }) => {
    if (target.value > 21) {
      setData({
        ...data,
        errors: true,
        errorMessage: 'Please make sure child ages are 21 or under',
      });
      return;
    }

    const answer = { ...data.answer, [target.name]: target.value };

    setData({
      ...data,
      answer,
    });
  };

  useEffect(() => {
    const updateAnswerFromLocal = () => {
      const localQuestions = getFromLocalStorage('questions');

      if (localQuestions) {
        const thisQuestion = localQuestions.filter(
          (q) => q['questionNo'] === number
        );

        thisQuestion.map((qu) => {
          setData({
            ...data,
            answer: qu.answer,
          });
        });
      }
    };

    updateAnswerFromLocal();
  }, []);

  let inputRefs = [];

  const validate = ({ target }) => {
    inputRefs.every((e) => {
      const formData = new FormData(target);
      let total = 0;
      for (let [_, val] of formData.entries()) {
        total += val * 1;
      }

      if (total === 0) {
        setData({
          ...data,
          errors: true,
        });
      } else {
        handleSubmit(data);
      }
    });
  };

  return (
    <Flex direction="column">
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        infoModal={infoModal}
        infoModalContent={infoModalContent}
        description={description}
        infoModalInsurance={infoModalInsurance}
        errorMessage={data.errorMessage}
        errors={data.errors}
      />
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          validate(e);
        }}
      >
        <Flex flexWrap="wrap" justifyContent="space-between" mt={4}>
          {[...Array(inputs).keys()].map((i) => {
            const newRef = createRef();
            inputRefs.push(newRef);
            return (
              <Box key={i} w="calc(50% - 20px)" mr="20px">
                <Input
                  type="text"
                  name={`AgeChild${i + 1}`}
                  onChange={handleChange}
                  ref={newRef}
                  placeholder={`Child ${i + 1}`}
                  min="0"
                  max="21"
                  minLength="1"
                  maxLength="2"
                  mt={2}
                  value={data.answer['AgeChild' + (i + 1)] || ''}
                  title="Child age needs to be between 0 & 21"
                  pattern="\d*"
                />
              </Box>
            );
          })}
        </Flex>
        <Button type="submit" bg="brandGreen" bgHover="brandGreenHover" mt="6">
          Next
        </Button>
      </Box>
    </Flex>
  );
}
