import React, { useState, useEffect } from 'react';
import {
  Flex,
  Box,
  Select as ChakraSelect,
  VisuallyHidden,
  Icon,
  Text,
  ControlBox,
} from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function Select({ details }) {
  const { question, handleSubmit, errors } = details;
  const {
    id,
    number,
    title,
    subTitle,
    description,
    answers,
    errorMessage,
    infoModal,
    infoModalContent,
    infoModalInsurance,
    loop,
    loopStep,
    loopCollect,
    options,
    dontKnowOption,
    dontKnowOptionLabel,
    dontKnowCollect,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: '',
    answer: {},
    collectOne: '',
    disabled: false,
    question: emailQuestion,
  });

  const handleChange = ({ target }) => {
    if (target.name === 'answerSelect') {
      const step = target.options[target.selectedIndex].getAttribute(
        'data-nextstep'
      );

      const collect = target.options[target.selectedIndex].getAttribute(
        'data-collect'
      );

      const label = target.options[target.selectedIndex].getAttribute(
        'data-label'
      );

      setData({
        ...data,
        answer: { [collect]: target.value },
        collectOne: String([label]),
        step,
      });
    }

    if (target.name === 'loopSelect') {
      setData({
        ...data,
        answer: { [loopCollect]: target.value },
        collectOne: target.value,
        step: loopStep,
      });
    }
  };

  const updateAnswerFromLocal = () => {
    const localQuestions = getFromLocalStorage('questions');
    if (localQuestions) {
      const thisQuestion = localQuestions.filter(
        (q) => q['questionNo'] === number
      );

      thisQuestion.map((qu) => {
        setData({
          ...data,
          answer: qu.answer,
          step: qu.step,
          collectOne: qu.collectOne,
          disabled: qu.disabled,
        });
      });
    }
  };

  useEffect(() => {
    updateAnswerFromLocal();
  }, []);

  const handleDontKnow = () => {
    setData({
      ...data,
      disabled: !data.disabled,
      answer: { [dontKnowCollect]: 0 },
      collectOne: '',
      step: loopStep,
    });
  };

  return (
    <Flex direction="column">
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        infoModal={infoModal}
        infoModalContent={infoModalContent}
        description={description}
        infoModalInsurance={infoModalInsurance}
        errors={errors}
        errorMessage={errorMessage}
      />

      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        <Flex direction="column">
          {answers && (
            <ChakraSelect
              placeholder="Select option"
              w={{ base: '100%', lg: '300px' }}
              onChange={handleChange}
              value={data.collectOne}
              name="answerSelect"
            >
              {answers.map((item, i) => {
                return (
                  <option
                    key={i}
                    data-nextstep={item.step}
                    data-collect={item.collect}
                    data-label={item.label}
                    value={item.label}
                    required
                  >
                    {item.label}
                  </option>
                );
              })}
            </ChakraSelect>
          )}

          {loop && (
            <ChakraSelect
              id="loopSelect"
              placeholder="Select option"
              name="loopSelect"
              w={{ base: '100%', lg: '300px' }}
              onChange={handleChange}
              value={data.collectOne}
              disabled={data.disabled}
            >
              {[...Array(options).keys()].map((i) => {
                return (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                );
              })}
            </ChakraSelect>
          )}

          {dontKnowOption && (
            <Text as="label" mt={4}>
              <VisuallyHidden
                as="input"
                type="checkbox"
                id="checkOne"
                onChange={handleDontKnow}
                checked={data.disabled}
              />

              <ControlBox
                borderWidth="2px"
                size="16px"
                rounded="sm"
                _checked={{
                  bg: 'blue.500',
                  color: 'white',
                  borderColor: 'blue.500',
                }}
                _focus={{
                  borderColor: 'blue.600',
                  boxShadow: 'outline',
                }}
              >
                <Icon name="check" size="12px" />
              </ControlBox>

              <Box as="span" verticalAlign="top" ml={2}>
                {dontKnowOptionLabel}
              </Box>
            </Text>
          )}
        </Flex>
        <Button type="submit" bg="brandGreen" bgHover="brandGreenHover" mt="6">
          Next
        </Button>
      </Box>
    </Flex>
  );
}
