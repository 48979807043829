import React from 'react';
import { Box, VisuallyHidden, ControlBox } from '@chakra-ui/core';

export default function RadioInput(props) {
  const {
    id,
    name,
    onChange,
    step,
    collect,
    value,
    checked,
    label,
    option,
  } = props;

  return (
    <Box
      as="label"
      display="flex"
      alignItems="center"
      px={{ base: 2, lg: 4 }}
      py={4}
      w="100%"
      cursor="pointer"
      borderRadius="md"
    >
      <VisuallyHidden
        type="radio"
        as="input"
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        data-nextstep={step}
        data-collect={collect}
        data-option={option}
        data-label={label}
        checked={checked === label}
      />
      <ControlBox
        size="16px"
        bg="white"
        border="2px"
        rounded="full"
        type="radio"
        borderColor="inherit"
        _focus={{ boxShadow: 'outline' }}
        _hover={{ borderColor: 'gray.300' }}
        _disabled={{ opacity: '40%' }}
        _checked={{ bg: 'blue.500', borderColor: 'blue.500' }}
      >
        <Box size="50%" bg="white" rounded="full" />
      </ControlBox>

      <Box
        as="span"
        ml={2}
        verticalAlign="center"
        userSelect="none"
        fontSize={{ base: '14px', lg: '16px' }}
      >
        {label}
      </Box>
    </Box>
  );
}
