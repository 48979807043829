import React, { useState, useEffect } from 'react';
import {
  Flex,
  Box,
  Input,
  VisuallyHidden,
  ControlBox,
  Icon,
  Text,
} from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function CoverAmount({ details }) {
  const { question, handleSubmit } = details;
  const {
    id,
    number,
    title,
    subTitle,
    description,
    dontKnowOptionLabel,
    infoModal,
    infoModalContent,
    optionsOne,
    optionsTwo,
    step,
    emailQuestion,
    validationError,
    minLength,
    maxLength,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step,
    answer: {},
    disabled: false,
    inputAnswerOne: '',
    inputAnswerTwo: '',
    inputCheckboxOne: false,
    inputCheckboxTwo: false,
    formOneCollect: '',
    formTwoCollect: '',
    errors: false,
    question: emailQuestion,
  });

  const setAnswer = () => {
    let answer;

    if (data.inputCheckboxOne === true && data.inputCheckboxTwo === true) {
      answer = {
        DKLifeExistingCoverAmount: 'DK/NOLifeExistingCoverAmount',
        DKCICExistingCoverAmount: 'DK/NOCICExistingCoverAmount',
      };
    }

    if (data.inputCheckboxOne === true && data.inputAnswerTwo.length > 0) {
      answer = {
        DKLifeExistingCoverAmount: 'DK/NOLifeExistingCoverAmount',
        CICExistingCoverAmount: `CIC: ${data.inputAnswerTwo}`,
      };
    }

    if (data.inputCheckboxOne === false && data.inputCheckboxTwo === false) {
      answer = {
        LifeExistingCoverAmount: `LIFE: ${data.inputAnswerOne}`,
        CICExistingCoverAmount: `CIC: ${data.inputAnswerTwo}`,
      };
    }

    if (data.inputCheckboxTwo === true && data.inputAnswerOne.length > 0) {
      answer = {
        LifeExistingCoverAmount: `LIFE: ${data.inputAnswerOne}`,
        DKCICExistingCoverAmount: 'DK/NOCICExistingCoverAmount',
      };
    }

    setData({
      ...data,
      answer: answer,
    });
  };

  useEffect(() => {
    setAnswer();
  }, [
    data.inputAnswerOne,
    data.inputAnswerTwo,
    data.inputCheckboxOne,
    data.inputCheckboxTwo,
  ]);

  const handleChange = ({ target }) => {
    const collect = target.attributes['data-collect'].value;

    if (target.name === 'LifeExistingCoverAmount') {
      setData({
        ...data,
        inputAnswerOne: target.value,
        formOneCollect: collect,
      });
    }

    if (target.name === 'DK/NOLifeExistingCoverAmount') {
      setData({
        ...data,
        inputCheckboxOne: !data.inputCheckboxOne,
        inputAnswerOne: '',
        formOneCollect: collect,
      });
    }

    if (target.name === 'CICExistingCoverAmount') {
      setData({
        ...data,
        inputAnswerTwo: target.value,
        formTwoCollect: collect,
      });
    }

    if (target.name === 'DK/NOCICExistingCoverAmount') {
      setData({
        ...data,
        inputCheckboxTwo: !data.inputCheckboxTwo,
        inputAnswerTwo: '',
        formTwoCollect: collect,
      });
    }
  };

  const updateAnswerFromLocal = () => {
    const localQuestions = getFromLocalStorage('questions');
    if (localQuestions) {
      const thisQuestion = localQuestions.filter(
        (q) => q['questionNo'] === number
      );

      thisQuestion.map((qu) => {
        setData({
          ...data,
          answer: qu.answer,
          step: qu.step,
          inputAnswerOne: qu.inputAnswerOne,
          inputAnswerTwo: qu.inputAnswerTwo,
          inputCheckboxOne: qu.inputCheckboxOne,
          inputCheckboxTwo: qu.inputCheckboxTwo,
        });
      });
    }
  };

  useEffect(() => {
    updateAnswerFromLocal();
  }, []);

  const validate = () => {
    const inputOne = document.querySelector('#inputOne');
    const inputTwo = document.querySelector('#inputTwo');
    const checkOne = document.querySelector('#checkOne');
    const checkTwo = document.querySelector('#checkTwo');

    if (inputOne.value === '' && data.inputCheckboxOne === false) {
      setData({
        ...data,
        errors: true,
        errorMessage: 'Please add life insurance amount',
      });
      return;
    }
    if (inputTwo.value === '' && data.inputCheckboxTwo === false) {
      setData({
        ...data,
        errors: true,
        errorMessage: 'Please add critical illness amount',
      });
      return;
    }

    if (
      (inputOne.value.length < minLength && !checkOne.checked) ||
      (inputTwo.value.length < minLength && !checkTwo.checked)
    ) {
      setData({
        ...data,
        errors: true,
        errorMessage: validationError,
      });
      return;
    }

    handleSubmit(data);
  };

  return (
    <Flex direction="column" w="100%">
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        description={description}
        infoModal={infoModal}
        infoModalContent={infoModalContent}
        errors={data.errors}
        errorMessage={data.errorMessage}
      />

      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          validate();
        }}
      >
        <Box>
          {optionsOne &&
            optionsOne.map((item, i) => {
              return (
                <Box key={i} mt={4}>
                  <Text as="h5" fontWeight="bold" mb={1}>
                    {item.title}
                  </Text>
                  {item.type === 'number' && (
                    <Box>
                      <Input
                        id="inputOne"
                        name={item.collect}
                        onChange={handleChange}
                        data-collect={item.collect}
                        data-nextstep={item.step}
                        type="tel"
                        placeholder={item.label}
                        disabled={data.inputCheckboxOne}
                        value={data.inputAnswerOne}
                        max={item.max}
                        maxLength={maxLength}
                        pattern="\d*"
                      />
                    </Box>
                  )}
                  {item.type === 'checkbox' && (
                    <Box>
                      <CheckBox
                        id="checkOne"
                        type={item.type}
                        name={item.collect}
                        onChange={handleChange}
                        collect={item.collect}
                        step={item.step}
                        checked={data.inputCheckboxOne}
                        label={dontKnowOptionLabel}
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
          {optionsTwo &&
            optionsTwo.map((item, i) => {
              return (
                <Box key={i} mt={4}>
                  <Text as="h5" fontWeight="bold" mb={1}>
                    {item.title}
                  </Text>
                  {item.type === 'number' && (
                    <Input
                      id="inputTwo"
                      name={item.collect}
                      onChange={handleChange}
                      data-collect={item.collect}
                      data-nextstep={item.step}
                      type="tel"
                      placeholder={item.label}
                      disabled={data.inputCheckboxTwo}
                      value={data.inputAnswerTwo}
                      max={item.max}
                      maxLength={maxLength}
                      pattern="\d*"
                    />
                  )}

                  {item.type === 'checkbox' && (
                    <Box>
                      <CheckBox
                        id="checkTwo"
                        type={item.type}
                        name={item.collect}
                        onChange={handleChange}
                        collect={item.collect}
                        step={item.step}
                        checked={data.inputCheckboxTwo}
                        label={dontKnowOptionLabel}
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
        </Box>

        <Button type="submit" bg="brandGreen" bgHover="brandGreenHover" mt="6">
          Next
        </Button>
      </Box>
    </Flex>
  );
}

export function CheckBox({
  id,
  type,
  name,
  onChange,
  collect,
  step,
  checked,
  label,
}) {
  return (
    <Text as="label">
      <VisuallyHidden
        as="input"
        id={id}
        type={type}
        name={name}
        onChange={onChange}
        data-collect={collect}
        data-nextstep={step}
        checked={checked}
        value=""
      />

      <ControlBox
        borderWidth="2px"
        size="16px"
        rounded="sm"
        _checked={{
          bg: 'blue.500',
          color: 'white',
          borderColor: 'blue.500',
        }}
        _focus={{
          borderColor: 'blue.600',
          boxShadow: 'outline',
        }}
      >
        <Icon name="check" size="12px" />
      </ControlBox>

      <Box as="span" verticalAlign="top" ml={2}>
        {label}
      </Box>
    </Text>
  );
}
