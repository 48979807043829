import React, { useState, useEffect } from 'react';
import { getFromLocalStorage } from '../../utils/functions';
import { Flex, Box, Text, Select } from '@chakra-ui/core';

import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function AlcoholSwitcher({ details }) {
  const { question, handleSubmit, errors } = details;
  const {
    id,
    number,
    title,
    subTitle,
    description,
    errorMessage,
    step,
    answersOne,
    answersTwo,
    infoModal,
    infoModalContent,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: step,
    answer: {},
    collectOne: '',
    collectTwo: '',
    answerOne: '',
    answerTwo: '',
    errors: false,
    question: emailQuestion,
  });

  const handleChange = ({ target }) => {
    const collect = target.options[target.selectedIndex].getAttribute(
      'data-collect'
    );

    const label = target.options[target.selectedIndex].getAttribute(
      'data-label'
    );

    if (target.name === 'radioOne') {
      setData({
        ...data,
        collectOne: collect,
        answerOne: label,
      });
    }

    if (target.name === 'radioTwo') {
      setData({
        ...data,
        collectTwo: collect,
        answerTwo: label,
      });
    }
  };

  useEffect(() => {
    const updateAnswer = () => {
      const { collectOne, answerOne, collectTwo, answerTwo } = data;
      const newAnswer = {
        [collectOne]: `RECIPIENT_NAME: ${answerOne}`,
        [collectTwo]: `P_NAME: ${answerTwo}`,
      };
      setData({
        ...data,
        answer: newAnswer,
      });
    };

    updateAnswer();
  }, [data.collectOne, data.collectTwo]);

  useEffect(() => {
    const updateAnswerFromLocal = () => {
      const localQuestions = getFromLocalStorage('questions');
      if (localQuestions) {
        const thisQuestion = localQuestions.filter(
          (q) => q['questionNo'] === number
        );

        thisQuestion.map((qu) => {
          setData({
            ...data,
            answer: qu.answer,
            collectOne: String(qu.collectOne),
            collectTwo: String(qu.collectTwo),
            answerOne: String(qu.answerOne),
            answerTwo: String(qu.answerTwo),
          });
        });
      }
    };

    updateAnswerFromLocal();
  }, []);

  return (
    <Flex direction="column">
      {errors && <h5 style={{ color: 'red' }}>{errorMessage}</h5>}
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        description={description}
        infoModal={infoModal}
        infoModalContent={infoModalContent}
        errors={data.errors}
        errorMessage={errorMessage}
      />

      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        <Flex direction={{ base: 'column', lg: 'row' }}>
          <Box>
            <Text as="h5" fontWeight="bold" mb={1}>
              RECIPIENT_NAME
            </Text>
            <Select
              placeholder="Select option"
              w={{ base: '100%', lg: '300px' }}
              onChange={handleChange}
              value={data.answerOne}
              name="radioOne"
              isRequired
            >
              {answersOne &&
                answersOne.map((item, i) => {
                  return (
                    <option
                      key={i}
                      onChange={handleChange}
                      data-nextstep={item.step}
                      data-collect={item.collect}
                      data-label={item.label}
                      value={item.label}
                      required
                    >
                      {item.label}
                    </option>
                  );
                })}
            </Select>
          </Box>
          <Box ml={{ base: 0, lg: 10 }} mt={{ base: 4, lg: 0 }}>
            <Text as="h5" fontWeight="bold" mb={1}>
              P_NAME
            </Text>
            <Select
              placeholder="Select option"
              w={{ base: '100%', lg: '300px' }}
              onChange={handleChange}
              value={data.answerTwo}
              name="radioTwo"
              isRequired
            >
              {answersTwo &&
                answersTwo.map((item, i) => {
                  return (
                    <option
                      key={i}
                      onChange={handleChange}
                      data-nextstep={item.step}
                      data-collect={item.collect}
                      data-label={item.label}
                      value={item.label}
                      required
                    >
                      {item.label}
                    </option>
                  );
                })}
            </Select>
          </Box>
        </Flex>

        <Button type="submit" bg="brandGreen" bgHover="brandGreenHover" mt="6">
          Next
        </Button>
      </Box>
    </Flex>
  );
}
