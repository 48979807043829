import React, { useState, useEffect } from 'react';
import { Flex, Box, FormLabel, Switch } from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import QuestionHeader from '../partials/QuestionHeader';
import CustomInput from '../inputs/CustomInput';
import Button from '../partials/Button';

export default function HeightSwitcher({ details }) {
  const { question, handleSubmit, errors } = details;
  const {
    id,
    number,
    title,
    subTitle,
    description,
    errorMessage,
    step,
    answers,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: step,
    answer: {},
    ft: '',
    in: '',
    cm: '',
    unitSwitch: false,
    question: emailQuestion,
  });

  const handleChange = ({ target }) => {
    setData({
      ...data,
      [target.name]: target.value,
    });
  };

  const setAnswer = () => {
    let updatedAnswer;

    if (data.unitSwitch === true) {
      updatedAnswer = { Height: `${data.cm}Cm` };
    } else {
      updatedAnswer = { Height: `${data.ft}Ft / ${data.in}In` };
    }

    setData({
      ...data,
      answer: updatedAnswer,
      unitSwitch: data.unitSwitch,
    });
  };

  useEffect(() => {
    setAnswer();
  }, [data.ft, data.in, data.cm, data.unitSwitch]);

  useEffect(() => {
    const updateAnswerFromLocal = () => {
      const localQuestions = getFromLocalStorage('questions');

      if (localQuestions) {
        const thisQuestion = localQuestions.filter(
          (q) => q['questionNo'] === number
        );

        thisQuestion.map((qu) => {
          setData({
            ...data,
            answer: qu.answer,
            ft: qu.ft,
            in: qu.in,
            cm: qu.cm,
            unitSwitch: qu.unitSwitch,
          });
        });
      }
    };
    updateAnswerFromLocal();
  }, []);

  const toggleUnits = () => {
    setData({
      ...data,
      unitSwitch: !data.unitSwitch,
    });
  };

  return (
    <Flex direction="column">
      {errors && <h5 style={{ color: 'red' }}>{errorMessage}</h5>}
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        description={description}
      />
      <Box
        as="form"
        mt={4}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        {answers &&
          answers.map((item, i) => {
            return (
              <Flex key={i} direction="column">
                {data.unitSwitch === false ? (
                  <Flex>
                    <CustomInput
                      name="ft"
                      label="ft"
                      min="2"
                      max="8"
                      value={data.ft}
                      handleChange={handleChange}
                      mr="4"
                      required={data.unitSwitch ? '' : 'required'}
                    />
                    <CustomInput
                      name="in"
                      label="in"
                      min="0"
                      max="11"
                      value={data.in}
                      handleChange={handleChange}
                      required={data.unitSwitch ? '' : 'required'}
                    />
                  </Flex>
                ) : (
                  <>
                    <CustomInput
                      name="cm"
                      label="cm"
                      min="60"
                      max="240"
                      value={data.cm}
                      handleChange={handleChange}
                      required={data.unitSwitch ? 'required' : ''}
                    />
                  </>
                )}
              </Flex>
            );
          })}
        <Flex direction="column" mt={4}>
          <Flex align="center">
            <Switch
              id="toggle-unit"
              mr={2}
              onChange={toggleUnits}
              isChecked={data.unitSwitch}
            />
            <FormLabel htmlFor="toggle-unit">
              Change unit to
              {data.unitSwitch ? ' Ft/In' : ' Cm'}
            </FormLabel>
          </Flex>
          <Box alignSelf="flex-start" mt={6}>
            <Button type="submit" bg="brandGreen" bgHover="brandGreenHover">
              Next
            </Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}
