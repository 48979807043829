import React, { useState, useEffect } from 'react';
import { Flex, Box, Textarea, Text } from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import CallBack from '../partials/CallBack';
import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function FreeTypeCallbackMulti({ details }) {
  const { question, handleSubmit, errors } = details;
  const {
    id,
    number,
    title,
    errorMessage,
    step,
    subTitle,
    description,
    collectOne,
    collectTwo,
    infoModalContent,
    infoModal,
    placeholder,
    callback,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: step,
    answer: '',
    collectOne: '',
    collectTwo: '',
    modalOpen: false,
    question: emailQuestion,
  });

  const handleChangeOne = ({ target }) => {
    const setAnswer = {
      ...data.answer,
      [target.name]: `RECIPIENT_NAME: ${target.value}`,
    };
    setData({
      ...data,
      answer: setAnswer,
      collectOne: target.value,
    });
  };

  const handleChangeTwo = ({ target }) => {
    const setAnswer = {
      ...data.answer,
      [target.name]: `P_NAME: ${target.value}`,
    };
    setData({
      ...data,
      answer: setAnswer,
      collectTwo: target.value,
    });
  };

  const toggleModal = () => {
    setData({
      ...data,
      modalOpen: !data.modalOpen,
    });
  };

  const updateAnswerFromLocal = () => {
    const localQuestions = getFromLocalStorage('questions');
    if (localQuestions) {
      const thisQuestion = localQuestions.filter(
        (q) => q['questionNo'] === number
      );

      thisQuestion.map((qu) => {
        setData({
          ...data,
          answer: qu.answer,
          collectOne: qu.collectOne,
          collectTwo: qu.collectTwo,
        });
      });
    }
  };

  useEffect(() => {
    updateAnswerFromLocal();
  }, []);

  return (
    <Flex direction="column">
      {callback && (
        <>
          <CallBack isOpen={data.modalOpen} toggle={toggleModal} />
          <Box mb={4}>
            <Button
              onClick={toggleModal}
              bg="brandOrange"
              _hover={{ bg: 'brandOrangeHover' }}
              _active={{ bg: 'brandOrangeHover' }}
              color="white"
            >
              Request callback
            </Button>
          </Box>
        </>
      )}

      <QuestionHeader
        title={title}
        subTitle={subTitle}
        infoModal={infoModal}
        infoModalContent={infoModalContent}
        description={description}
        errorMessage={errorMessage}
        errors={errors}
      />

      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        <Box mt={4}>
          <Text as="h5" fontWeight="bold">
            Yourself
          </Text>
          <Textarea
            cols="30"
            rows="10"
            onChange={handleChangeOne}
            name={collectOne}
            value={data.collectOne}
            required="required"
            placeholder={placeholder}
          ></Textarea>
        </Box>

        <Box mt={4}>
          <Text as="h5" fontWeight="bold">
            P_NAME
          </Text>
          <Textarea
            cols="30"
            rows="10"
            onChange={handleChangeTwo}
            name={collectTwo}
            value={data.collectTwo}
            required="required"
            placeholder={placeholder}
          ></Textarea>
        </Box>
        <Button type="submit" bg="brandGreen" bgHover="brandGreenHover" mt="6">
          Next
        </Button>
      </Box>
    </Flex>
  );
}
