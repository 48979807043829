import React, { useState, useEffect } from 'react';
import { Flex, Box, Input, Checkbox, Grid } from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function CheckBox({ details }) {
  const { question, errors, handleSubmit } = details;
  const {
    id,
    number,
    title,
    errorMessage,
    step,
    subTitle,
    description,
    answers,
    otherField,
    otherCollect,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: step,
    answer: {},
    question: emailQuestion,
  });

  const handleChange = ({ target }) => {
    if (target.checked) {
      setData({
        ...data,
        answer: { ...data.answer, [target.name]: target.value },
      });
    } else {
      const removeItem = target.name;
      const { [removeItem]: remove, ...rest } = data.answer;
      setData({
        ...data,
        answer: rest,
      });
    }
  };

  const handleTextInput = ({ target }) => {
    const { name, value } = target;
    if (!value) {
      const removeItem = name;
      const { [removeItem]: remove, ...rest } = data.answer;
      setData({
        ...data,
        answer: rest,
      });
    } else {
      setData({
        ...data,
        answer: { ...data.answer, [name]: value },
      });
    }
  };

  const updateAnswerFromLocal = () => {
    const localQuestions = getFromLocalStorage('questions');
    if (localQuestions) {
      const thisQuestion = localQuestions.filter(
        (q) => q['questionNo'] === number
      );

      thisQuestion.map((qu) => {
        setData({
          ...data,
          step: qu.step,
          answer: qu.answer,
        });
      });
    }
  };

  const checkCheckboxes = (name) => {
    if (name in data.answer) {
      return true;
    }
  };

  useEffect(() => {
    updateAnswerFromLocal();
  }, []);

  return (
    <Flex direction="column" w="100%">
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        errorMessage={errorMessage}
        errors={errors}
        description={description}
      />

      <Box
        as="form"
        mt={4}
        w="100%"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            lg: 'repeat(auto-fit, minmax(25%, 1fr))',
          }}
          gridAutoRows="1fr"
          gap={6}
        >
          {answers &&
            answers.map((item, i) => {
              return (
                <Flex
                  key={i}
                  p={4}
                  bg="gray.100"
                  borderRadius="md"
                  alignItems="flex-start"
                >
                  <Checkbox
                    name={item.collect}
                    id={i}
                    onChange={handleChange}
                    value={item.label}
                    isChecked={checkCheckboxes(item.collect)}
                    w="100%"
                    h="100%"
                    display="flex"
                    required
                  >
                    {item.label}
                  </Checkbox>
                </Flex>
              );
            })}
        </Grid>
        {otherField && (
          <Box alignSelf="flex-start" mt={4}>
            <Input
              type="text"
              placeholder="Other"
              name={otherCollect}
              onChange={handleTextInput}
              value={data.answer['ConcernOther']}
              w={{ base: '100%', lg: '50%' }}
            />
          </Box>
        )}
        <Button type="submit" bg="brandGreen" bgHover="brandGreenHover" mt="6">
          Next
        </Button>
      </Box>
    </Flex>
  );
}
