import React, { useState, useEffect } from "react";
import { Flex, Box, Text, Textarea, Alert, AlertIcon } from "@chakra-ui/core";
import { navigate } from "gatsby";
import axios from "axios";

import { getFromLocalStorage } from "../../utils/functions";
import Button from "../partials/Button";

export default function Output() {
  const [data, setData] = useState({
    answers: [],
    FactFindCompletionMessage: "",
    id: "",
    firstName: "",
    lastName: "",
    phone: "",
    partnerFirstName: "",
    partnerLastName: "",
    FactFindCompletionMessage: "",
    formErrors: false,
    errorMessage: "",
    errorSubMessage: "",
    questions: {},
    buttonDisabled: ''
  });

  useEffect(() => {
    const getData = () => {
      const questions = getFromLocalStorage("questions");
      const userDetails = getFromLocalStorage("userDetails");

      if (questions && userDetails) {
        const getAnswers = questions.find((x) => x.questionNo === "04A");

        if (getAnswers) {
          let data = getAnswers.answer;

          if ("ConcernOther" in data) {
            const removeItem = "ConcernOther";
            const { [removeItem]: remove, ...rest } = data;
            data = rest;
          }

          const formattedAnswer = Object.values(data).map((i) => ` ${i}`);

          setData({
            ...data,
            answers: formatString(formattedAnswer),
            id: userDetails["id"],
            firstName: userDetails["firstName"],
            lastName: userDetails["lastName"],
            phone: userDetails["phone"],
            partnerFirstName: userDetails["partnerFirstName"]
              ? userDetails["partnerFirstName"]
              : "",
            partnerLastName: userDetails["partnerLastName"]
              ? userDetails["partnerLastName"]
              : "",
            questions,
            buttonDisabled: false
          });
        }
      }
    };

    getData();
  }, []);

  const formatString = (data) => {
    if (data.length === 1) {
      return String(data);
    } else if (data.length === 2) {
      const replacedString = ` and to ${data[1]}`;
      data[1] = replacedString;
      return String(data).replace(/,/g, "");
    } else if (data.length > 2) {
      const last = Object.keys(data).pop();
      data[last] = ` and to ${data[last]}`;
      const replacedText = String(data).replace(/,(?=[^,]*$)/, "");
      return replacedText;
    }
  };

  const handleChange = ({ target }) => {
    setData({
      ...data,
      FactFindCompletionMessage: target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setData({
      ...data,
      buttonDisabled: true
    })

    const emailSubject = `Essential Online Fact Find Completed by ${data.firstName} ${data.lastName} ${data.id}`;
    const emailHeading = `Essential Online Fact Find Completed`;

    await axios
      .post(
        `${process.env.GATSBY_SUBMIT_API_ENDPOINT}`,
        {
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          partnerFirstName: data.partnerFirstName,
          partnerLastName: data.partnerLastName,
          questions: data.questions,
          FactFindCompletionMessage: data.FactFindCompletionMessage,
          emailSubject: emailSubject,
          emailHeading: emailHeading,
          emailType: 1
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        navigate(`/thank-you?user_name=${data.firstName}`);
      })
      .catch((err) => {
        setData({
          ...data,
          formErrors: true,
          formSubmitted: true,
          errorMessage: "Ooops",
          errorSubMessage:
            "Something went wrong, your request has not been submitted, please call me on 01625 466384.",
          serverError: true,
        });
      });
  };

  return (
    <Box w="100%">
      <Flex direction="column" w="100%">
        {data.formErrors ? (
          <Flex direction="column" w="100%">
            <Alert status="error" variant="left-accent">
              <AlertIcon />
              {data.errorMessage}
            </Alert>
            {data.errorSubMessage && (
              <Flex mt={4}>
                <Text>{data.errorSubMessage}</Text>
              </Flex>
            )}
          </Flex>
        ) : (
          <>
            <Text as="h1">
              That’s great RECIPIENT_NAME. With your answers to my questions,
              I’ll get to work, look at your options and come back to you soon
              with a recommendation that will make sure that you have money to
              {data.answers}.
            </Text>
            <Text mt={2}>
              Before we finish, if there's anything else that you think I may
              need to know that's relevant to your application regarding your
              current situation, health, finances or anything that may influence
              my recommendation, please let me know below:
            </Text>

            <Box as="form" onSubmit={handleSubmit} method="POST">
              <Box mt={2}>
                <Textarea
                  onChange={handleChange}
                  placeholder="Please provide any further information here"
                ></Textarea>
              </Box>
              <Text as="h3" mt={4}>
                Finally, as I haven’t spoken to you in person, before I go and
                get quotes from our insurers, please could you click the button
                below to confirm you are happy for me to do so.
              </Text>
              <Flex justifyContent="center" w="100%" mt="4">
                <Button type="submit" bg="brandGreen" bgHover="brandGreenHover" disabled={data.buttonDisabled}>
                  GO AHEAD
                </Button>
              </Flex>
            </Box>

            <Flex mt={6} w="100%" justifyContent="center" bg="gray.100">
              <Text fontSize="12px;" p={4} display="block">
                We only ask for personal information so we can help you find the
                best policy for your needs. By clicking ‘Go Ahead’ you agree to
                the terms of our{" "}
                <Text
                  as="a"
                  href="https://www.essentialinsurance.co.uk/privacy-policy/"
                  fontWeight="bold"
                  textDecoration="underline"
                >
                  privacy policy
                </Text>
              </Text>
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  );
}
