import React, { useState, useEffect, createRef } from 'react';
import {
  Flex,
  Box,
  Input,
  Text,
  VisuallyHidden,
  Icon,
  ControlBox,
} from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function FreeTypeMulti({ details }) {
  const { question, handleSubmit, errors } = details;
  const {
    id,
    number,
    answersOne,
    answersTwo,
    title,
    errorMessage,
    subTitle,
    description,
    step,
    hintText,
    infoModal,
    infoModalContent,
    emailQuestion,
    validationError,
    maxLength,
    otherIncomeValidation,
    customValidation,
    min,
    max,
    numericValidation,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step,
    answer: {},
    question: emailQuestion,
    input: ['', ''],
    collect: ['', ''],
    checked: [false, false],
  });

  const handleChange = ({ target }) => {
    const collect = target.attributes['data-collect'].value;

    if (target.name === 'checkOne') {
      setData({
        ...data,
        input: [target.value, data.input[1]],
        collect: [collect, data.collect[1]],
        checked: [!data.checked[0], data.checked[1]],
      });
    }

    if (target.name === 'checkTwo') {
      setData({
        ...data,
        input: [data.input[0], target.value],
        collect: [data.collect[0], collect],
        checked: [data.checked[0], !data.checked[1]],
      });
    }

    if (target.name === 'inputOne') {
      setData({
        ...data,
        input: [target.value, data.input[1]],
        collect: [collect, data.collect[1]],
      });
    }

    if (target.name === 'inputTwo') {
      setData({
        ...data,
        input: [data.input[0], target.value],
        collect: [data.collect[0], collect],
      });
    }
  };

  useEffect(() => {
    const generateAnswer = () => {
      const answer = {
        [data.collect[0]]: `${data.collect[0]}: ${data.input[0]}`,
        [data.collect[1]]: `${data.collect[1]}: ${data.input[1]}`,
      };

      setData({
        ...data,
        answer,
      });
    };

    generateAnswer();
  }, [data.input, data.collect, data.checked]);

  useEffect(() => {
    const updateAnswerFromLocal = () => {
      const localQuestions = getFromLocalStorage('questions');
      if (localQuestions) {
        const thisQuestion = localQuestions.filter(
          (q) => q['questionNo'] === number
        );

        thisQuestion.map((qu) => {
          setData({
            ...data,
            answer: qu.answer,
            input: qu.input,
            collect: qu.collect,
            checked: qu.checked,
          });
        });
      }
    };

    updateAnswerFromLocal();
  }, []);

  const validate = () => {
    if (otherIncomeValidation) {
      if (data.input[0] === '0' || data.input[1] === '0') {
        setData({
          ...data,
          errors: true,
          errorMessage: 'Please enter a value more than 0',
        });
        return;
      }

      if (
        (data.input[0] >= 1 && data.input[0] < 10) ||
        (data.input[1] >= 1 && data.input[1] < 10)
      ) {
        setData({
          ...data,
          errors: true,
          errorMessage: validationError,
        });
        return;
      }
    }

    if (customValidation) {
      if (
        data.input[0] < min ||
        data.input[0] > max ||
        data.input[1] < min ||
        data.input[1] > max
      ) {
        setData({
          ...data,
          errors: true,
          errorMessage:
            'You have entered an incorrect value. Please type in a number between 18 and 100.',
        });
        return;
      }
    }

    if (numericValidation) {
      if (!/^\d*$/.test(data.input[0]) || !/^\d*$/.test(data.input[1])) {
        setData({
          ...data,
          errors: true,
          errorMessage: 'You can only enter numeric values',
        });
        return;
      }
    }

    handleSubmit(data);
  };

  return (
    <Flex direction="column">
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        description={description}
        hintText={hintText}
        infoModal={infoModal}
        infoModalContent={infoModalContent}
        errors={data.errors}
        errorMessage={data.errorMessage}
      />

      <Box
        as="form"
        mt={2}
        onSubmit={(e) => {
          e.preventDefault();
          validate();
        }}
      >
        {answersOne &&
          answersOne.map((item, i) => {
            return (
              <Box key={i} mt={2}>
                {item.inputType === 'text' && (
                  <>
                    <Text as="h5" fontWeight="bold" mb={1}>
                      {item.label}
                    </Text>
                    <Input
                      type={item.type}
                      id={i}
                      name={item.name}
                      onChange={handleChange}
                      value={data.input[0]}
                      placeholder={item.placeholder}
                      isRequired
                      max={item.max}
                      maxLength={otherIncomeValidation && maxLength}
                      data-collect={item.collect}
                      disabled={data.checked[0]}
                    />
                  </>
                )}
                {item.inputType === 'checkbox' && (
                  <>
                    <Text as="label">
                      <VisuallyHidden
                        as="input"
                        id="checkOne"
                        type={item.inputType}
                        onChange={handleChange}
                        data-collect={item.collect}
                        value={item.value}
                        id={item.name}
                        name={item.name}
                        checked={data.checked[0]}
                      />

                      <ControlBox
                        borderWidth="2px"
                        size="16px"
                        rounded="sm"
                        _checked={{
                          bg: 'blue.500',
                          color: 'white',
                          borderColor: 'blue.500',
                        }}
                        _focus={{
                          borderColor: 'blue.600',
                          boxShadow: 'outline',
                        }}
                      >
                        <Icon name="check" size="12px" />
                      </ControlBox>

                      <Box as="span" verticalAlign="top" ml={2}>
                        {item.label}
                      </Box>
                    </Text>
                  </>
                )}
              </Box>
            );
          })}
        {answersTwo &&
          answersTwo.map((item, i) => {
            return (
              <Box key={i} mt={4}>
                {item.inputType === 'text' && (
                  <>
                    <Text as="h5" fontWeight="bold" mb={1}>
                      {item.label}
                    </Text>
                    <Input
                      type={item.type}
                      id={i}
                      name={item.name}
                      onChange={handleChange}
                      value={data.input[1]}
                      placeholder={item.placeholder}
                      isRequired
                      max={item.max}
                      maxLength={otherIncomeValidation && maxLength}
                      data-collect={item.collect}
                      disabled={data.checked[1]}
                    />
                  </>
                )}
                {item.inputType === 'checkbox' && (
                  <>
                    <Text as="label">
                      <VisuallyHidden
                        as="input"
                        id={item.name}
                        name={item.name}
                        type={item.inputType}
                        onChange={handleChange}
                        data-collect={item.collect}
                        value={item.value}
                        checked={data.checked[1]}
                      />

                      <ControlBox
                        borderWidth="2px"
                        size="16px"
                        rounded="sm"
                        _checked={{
                          bg: 'blue.500',
                          color: 'white',
                          borderColor: 'blue.500',
                        }}
                        _focus={{
                          borderColor: 'blue.600',
                          boxShadow: 'outline',
                        }}
                      >
                        <Icon name="check" size="12px" />
                      </ControlBox>

                      <Box as="span" verticalAlign="top" ml={2}>
                        {item.label}
                      </Box>
                    </Text>
                  </>
                )}
              </Box>
            );
          })}
        <Button type="submit" bg="brandGreen" bgHover="brandGreenHover" mt="6">
          Next
        </Button>
      </Box>
    </Flex>
  );
}
