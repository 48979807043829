import React, { useState, useEffect } from 'react';
import { Flex, Box, Textarea } from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import CallBack from '../partials/CallBack';
import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function FreeTypeCallback({ details }) {
  const { question, handleSubmit, errors } = details;
  const {
    id,
    number,
    title,
    errorMessage,
    step,
    subTitle,
    description,
    collect,
    infoModalContent,
    infoModal,
    placeholder,
    callback,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: step,
    answer: '',
    collect: '',
    modalOpen: false,
    question: emailQuestion,
  });

  const handleChange = ({ target }) => {
    if (!target.value.length) {
      setData({
        ...data,
        answer: {},
      });
    } else {
      setData({
        ...data,
        answer: { [target.name]: target.value },
      });
    }
  };

  const toggleModal = () => {
    setData({
      ...data,
      modalOpen: !data.modalOpen,
    });
  };

  const updateAnswerFromLocal = () => {
    const localQuestions = getFromLocalStorage('questions');

    if (localQuestions) {
      const thisQuestion = localQuestions.filter(
        (q) => q['questionNo'] === number
      );

      thisQuestion.map((qu) => {
        setData({
          ...data,
          answer: qu.answer,
        });
      });
    }
  };

  useEffect(() => {
    updateAnswerFromLocal();
  }, []);

  return (
    <Flex direction="column">
      {callback && (
        <>
          <CallBack isOpen={data.modalOpen} toggle={toggleModal} />
          <Box mb={4}>
            <Button
              onClick={toggleModal}
              bg="brandOrange"
              _hover={{ bg: 'brandOrangeHover' }}
              _active={{ bg: 'brandOrangeHover' }}
              color="white"
            >
              Request callback
            </Button>
          </Box>
        </>
      )}

      <QuestionHeader
        title={title}
        subTitle={subTitle}
        infoModal={infoModal}
        infoModalContent={infoModalContent}
        description={description}
        errors={errors}
        errorMessage={errorMessage}
      />

      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        <Box mt={4}>
          <Textarea
            cols="30"
            rows="10"
            onChange={handleChange}
            name={collect}
            value={Object.values(data.answer)}
            placeholder={placeholder}
          ></Textarea>
        </Box>
        <Box>
          <Button
            type="submit"
            bg="brandGreen"
            bgHover="brandGreenHover"
            mt="6"
          >
            Next
          </Button>
        </Box>
      </Box>
    </Flex>
  );
}
