import React, { useState, useEffect } from 'react';
import { Flex, Box, Text } from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import RadioInput from '../inputs/RadioInput';
import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function Radio({ details }) {
  const { question, handleSubmit, errors } = details;
  const {
    id,
    number,
    title,
    subTitle,
    description,
    answers,
    errorMessage,
    disclaimer,
    infoModal,
    infoModalContent,
    infoModalInsurance,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: '',
    answer: {},
    collectOne: '',
    question: emailQuestion,
  });

  const handleChange = ({ target }) => {
    const step = target.attributes['data-nextstep'].value;
    const collect = target.attributes['data-collect'].value;
    const label = target.attributes['data-label'].value;

    setData({
      ...data,
      answer: { [collect]: target.value },
      collectOne: String([label]),
      step,
    });
  };

  const updateAnswerFromLocal = () => {
    const localQuestions = getFromLocalStorage('questions');
    if (localQuestions) {
      const thisQuestion = localQuestions.filter(
        (q) => q['questionNo'] === number
      );

      thisQuestion.map((qu) => {
        setData({
          ...data,
          answer: qu.answer,
          step: qu.step,
          collectOne: qu.collectOne,
        });
      });
    }
  };

  useEffect(() => {
    updateAnswerFromLocal();
  }, []);

  return (
    <Flex direction="column" w="100%">
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        infoModal={infoModal}
        infoModalContent={infoModalContent}
        description={description}
        infoModalInsurance={infoModalInsurance}
        errors={errors}
        errorMessage={errorMessage}
      />

      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        <Flex direction={{ base: 'column', lg: 'row' }}>
          {answers &&
            answers.map((item, i) => {
              return (
                <Flex
                  key={i}
                  bg="white"
                  borderRadius="md"
                  alignItems="center"
                  margin={{ base: '0 0 10px 0', lg: '0 20px 0 0' }}
                  bg="gray.100"
                >
                  <RadioInput
                    id={i}
                    name="radio"
                    onChange={handleChange}
                    step={item.step}
                    collect={item.collect}
                    value={item.label}
                    checked={data.collectOne}
                    label={item.label}
                    required
                  />
                </Flex>
              );
            })}
        </Flex>
        <Box mt={6}>
          <Button type="submit" bg="brandGreen" bgHover="brandGreenHover">
            Next
          </Button>
        </Box>
      </Box>
      {disclaimer === true && (
        <Text fontSize="11px" mt={10}>
          By providing answers to these questions, you’ll help us to provide you
          with a recommendation and a tailored quote to suit your circumstances
          and needs. You don’t have to give us any of your personal information,
          but if we are collecting your data to provide our services to you and
          it’s necessary to enter into a contract, we will be unable to provide
          our services if you choose not to provide your information. We will
          always keep your personal information safe and secure. So that you’re
          informed of our privacy policy, this{' '}
          <Text
            as="a"
            href="https://www.essentialinsurance.co.uk/privacy-policy/ "
            target="_blank"
            fontWeight="bold"
            textDecoration="underline"
          >
            privacy notice
          </Text>{' '}
          tells you why Essential Insurance collects and uses your personal
          information. By continuing and answering these questions you agree
          that any information you provide online, by email or over the phone
          will be used in accordance with our privacy policy and you understand
          that any information you provide must be accurate and complete because
          this will be relied upon by Essential Insurance and the insurance
          companies we work with.
        </Text>
      )}
    </Flex>
  );
}
