import React, { useState, useEffect } from 'react';
import { Flex, Box, FormLabel, Switch, Text, Checkbox } from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import QuestionHeader from '../partials/QuestionHeader';
import CustomInput from '../inputs/CustomInput';
import Button from '../partials/Button';

export default function HeightSwitcherMulti({ details }) {
  const { question, handleSubmit, errors } = details;
  const {
    id,
    number,
    title,
    subTitle,
    description,
    errorMessage,
    step,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: step,
    answer: {},
    ft0: '',
    ft1: '',
    in0: '',
    in1: '',
    cm0: '',
    cm1: '',
    unitSwitch: false,
    dontKnow: false,
    question: emailQuestion,
  });

  const handleChange = ({ target }) => {
    setData({
      ...data,
      [target.name]: target.value,
    });
  };

  const setAnswer = () => {
    let updatedAnswer;

    if (data.unitSwitch === true) {
      const valueSwitch = data.dontKnow
        ? `P_NAME: Don't Know`
        : `P_NAME: ${data.cm1}Cm`;
      updatedAnswer = {
        RecipientNameHeight: `RECIPIENT_NAME: ${data.cm0}Cm`,
        Pname1Height: valueSwitch,
      };
    } else {
      const valueSwitch = data.dontKnow
        ? `P_NAME: Don't Know`
        : `P_NAME: ${data.ft1}Ft / ${data.in1}In`;

      updatedAnswer = {
        RecipientNameHeight: `RECIPIENT_NAME: ${data.ft0}Ft / ${data.in0}In`,
        Pname1Height: valueSwitch,
      };
    }

    setData({
      ...data,
      answer: updatedAnswer,
      unitSwitch: data.unitSwitch,
    });
  };

  useEffect(() => {
    setAnswer();
  }, [
    data.ft0,
    data.ft1,
    data.in0,
    data.in1,
    data.cm0,
    data.cm1,
    data.unitSwitch,
    data.dontKnow,
  ]);

  useEffect(() => {
    const updateAnswerFromLocal = () => {
      const localQuestions = getFromLocalStorage('questions');

      if (localQuestions) {
        const thisQuestion = localQuestions.filter(
          (q) => q['questionNo'] === number
        );

        thisQuestion.map((qu) => {
          setData({
            ...data,
            answer: qu.answer,
            ft0: qu.ft0,
            ft1: qu.ft1,
            in0: qu.in0,
            in1: qu.in1,
            cm0: qu.cm0,
            cm1: qu.cm1,
            unitSwitch: qu.unitSwitch,
            dontKnow: qu.dontKnow,
          });
        });
      }
    };
    updateAnswerFromLocal();
  }, []);

  const toggleUnits = () => {
    setData({
      ...data,
      unitSwitch: !data.unitSwitch,
    });
  };

  const toggleDontKnow = () => {
    setData({
      ...data,
      ft1: '',
      in1: '',
      cm1: '',
      dontKnow: !data.dontKnow,
    });
  };

  return (
    <Flex direction="column">
      {errors && <h5 style={{ color: 'red' }}>{errorMessage}</h5>}
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        description={description}
      />
      <Box
        as="form"
        mt={4}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        <Flex direction="column">
          <Flex mb={1}>
            <Text as="h5" fontWeight="bold">
              RECIPIENT_NAME
            </Text>
          </Flex>
          {data.unitSwitch === false ? (
            <Flex direction="column">
              <Flex>
                <CustomInput
                  name="ft0"
                  label="ft"
                  min="2"
                  max="8"
                  value={data.ft0}
                  handleChange={handleChange}
                  mr="4"
                  mb="2"
                  w={{ base: '100%', lg: '70px' }}
                  required={data.unitSwitch ? '' : 'required'}
                />
                <CustomInput
                  name="in0"
                  label="in"
                  min="0"
                  max="11"
                  w={{ base: '100%', lg: '70px' }}
                  value={data.in0}
                  handleChange={handleChange}
                  required={data.unitSwitch ? '' : 'required'}
                  mb="2"
                />
              </Flex>
            </Flex>
          ) : (
            <Flex direction="column">
              <CustomInput
                name="cm0"
                label="cm"
                min="60"
                max="240"
                w={{ base: '100%', lg: '70px' }}
                value={data.cm0}
                handleChange={handleChange}
                mb="2"
                required={data.unitSwitch ? 'required' : ''}
              />
            </Flex>
          )}
        </Flex>
        <Flex direction="column" mt={4}>
          <Flex mb={1}>
            <Text as="h5" fontWeight="bold">
              P_NAME
            </Text>
            <Checkbox
              onChange={toggleDontKnow}
              ml={2}
              isChecked={data.dontKnow}
            >
              Don't know
            </Checkbox>
          </Flex>
          {data.unitSwitch === false ? (
            <Flex direction="column">
              <Flex>
                <CustomInput
                  name="ft1"
                  label="ft"
                  min="2"
                  max="8"
                  w={{ base: '100%', lg: '70px' }}
                  value={data.ft1}
                  handleChange={handleChange}
                  mr="4"
                  mb="2"
                  required={data.unitSwitch ? '' : 'required'}
                  disabled={data.dontKnow}
                />
                <CustomInput
                  name="in1"
                  label="in"
                  min="0"
                  max="11"
                  w={{ base: '100%', lg: '70px' }}
                  value={data.in1}
                  handleChange={handleChange}
                  mb="2"
                  required={data.unitSwitch ? '' : 'required'}
                  disabled={data.dontKnow}
                />
              </Flex>
            </Flex>
          ) : (
            <Flex direction="column">
              <CustomInput
                name="cm1"
                label="cm"
                min="60"
                max="240"
                mb="2"
                w={{ base: '100%', lg: '70px' }}
                value={data.cm1}
                handleChange={handleChange}
                required={data.unitSwitch ? 'required' : ''}
                disabled={data.dontKnow}
              />
            </Flex>
          )}
        </Flex>
        <Flex direction="column" mt={4}>
          <Flex align="center">
            <Switch
              id="toggle-unit"
              mr={2}
              onChange={toggleUnits}
              isChecked={data.unitSwitch}
            />
            <FormLabel htmlFor="toggle-unit">
              Change unit to
              {data.unitSwitch ? ' Ft/In' : ' Cm'}
            </FormLabel>
          </Flex>
          <Box alignSelf="flex-start">
            <Button
              type="submit"
              bg="brandGreen"
              bgHover="brandGreenHover"
              mt="6"
            >
              Next
            </Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}
