import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import {
  replaceRecipientName,
  replacePartnerName,
  getFromLocalStorage,
} from '../utils/functions';

import Layout from '../components/layouts/Layout';
import Radio from '../components/forms/Radio';
import RadioMulti from '../components/forms/RadioMulti';
import FreeType from '../components/forms/FreeType';
import FreeTypeMulti from '../components/forms/FreeTypeMulti';
import Landing from '../components/landing-pages/Landing';
import PartnerName from '../components/forms/PartnerName';
import ChildAges from '../components/forms/ChildAges';
import FreeTypeCallback from '../components/forms/FreeTypeCallback';
import FreeTypeCallbackMulti from '../components/forms/FreeTypeCallbackMulti';
import CheckBox from '../components/forms/CheckBox';
import AlcoholSwitcher from '../components/forms/AlcoholSwitcher';
import OccupationSwitcher from '../components/forms/OccupationSwitcher';
import Output from '../components/landing-pages/Output';
import HeightSwitcher from '../components/forms/HeightSwitcher';
import HeightSwitcherMulti from '../components/forms/HeightSwitcherMulti';
import WeightSwitcher from '../components/forms/WeightSwitcher';
import WeightSwitcherMulti from '../components/forms/WeightSwitcherMulti';
import DatePicker from '../components/forms/DatePicker';
import Select from '../components/forms/Select';
import CoverAmount from '../components/forms/CoverAmount';

export default function Question({ pageContext: { question } }) {
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    replaceRecipientName('firstName');
    replacePartnerName('partnerFirstName');
  });

  const handleSubmit = async (data) => {
    if (!data.answer || Object.keys(data.answer).length === 0) {
      setErrors(true);
      return;
    }

    let localQuestions = (await getFromLocalStorage('questions')) || [];

    if (!localQuestions || !localQuestions.length) {
      localQuestions.push(data);
      localStorage.setItem('questions', JSON.stringify(localQuestions));
      navigate(`/${data.step}`);
    } else {
      const otherQuestions = localQuestions.filter(
        (q) => q['questionNo'] !== data.questionNo
      );
      const questionData = [data, ...otherQuestions];
      localStorage.setItem('questions', JSON.stringify(questionData));
      navigate(`/${data.step}`);
    }
  };

  return (
    <Layout>
      <ComponentSwitcher
        component={question.component}
        question={question}
        errors={errors}
        handleSubmit={handleSubmit}
      />
    </Layout>
  );
}

const ComponentSwitcher = (props) => {
  const { component, ...details } = props;

  if (component === 'Radio') {
    return <Radio details={details} />;
  }

  if (component === 'RadioMulti') {
    return <RadioMulti details={details} />;
  }

  if (component === 'FreeType') {
    return <FreeType details={details} />;
  }

  if (component === 'FreeTypeMulti') {
    return <FreeTypeMulti details={details} />;
  }

  if (component === 'Landing') {
    return <Landing details={details} />;
  }

  if (component === 'PartnerName') {
    return <PartnerName details={details} />;
  }

  if (component === 'ChildAges') {
    return <ChildAges details={details} />;
  }

  if (component === 'FreeTypeCallback') {
    return <FreeTypeCallback details={details} />;
  }

  if (component === 'FreeTypeCallbackMulti') {
    return <FreeTypeCallbackMulti details={details} />;
  }

  if (component === 'CheckBox') {
    return <CheckBox details={details} />;
  }

  if (component === 'AlcoholSwitcher') {
    return <AlcoholSwitcher details={details} />;
  }

  if (component === 'OccupationSwitcher') {
    return <OccupationSwitcher details={details} />;
  }

  if (component === 'Output') {
    return <Output details={details} />;
  }

  if (component === 'HeightSwitcher') {
    return <HeightSwitcher details={details} />;
  }

  if (component === 'WeightSwitcher') {
    return <WeightSwitcher details={details} />;
  }

  if (component === 'DatePicker') {
    return <DatePicker details={details} />;
  }

  if (component === 'HeightSwitcherMulti') {
    return <HeightSwitcherMulti details={details} />;
  }

  if (component === 'WeightSwitcherMulti') {
    return <WeightSwitcherMulti details={details} />;
  }

  if (component === 'Select') {
    return <Select details={details} />;
  }

  if (component === 'CoverAmount') {
    return <CoverAmount details={details} />;
  }
};
