import React, { useState, useEffect } from 'react';
import { Flex, Box, Text } from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import RadioInput from '../inputs/RadioInput';
import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function RadioMulti({ details }) {
  const { question, handleSubmit, errors } = details;
  const {
    id,
    number,
    title,
    subTitle,
    description,
    errorMessage,
    optionsOne,
    optionsTwo,
    collectOpts,
    titleOne,
    titleTwo,
    infoModal,
    infoModalContent,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: '',
    answer: {},
    collectOne: '',
    collectTwo: '',
    answerOne: '',
    answerTwo: '',
    question: emailQuestion,
  });

  const handleChange = ({ target }) => {
    const collect = target.attributes['data-collect'].value;

    if (target.name === 'radioOne') {
      setData({
        ...data,
        collectOne: collect,
        answerOne: target.value,
      });
    }

    if (target.name === 'radioTwo') {
      setData({
        ...data,
        collectTwo: collect,
        answerTwo: target.value,
      });
    }
  };

  const updateAnswerFromLocal = () => {
    const localQuestions = getFromLocalStorage('questions');
    if (localQuestions) {
      const thisQuestion = localQuestions.filter(
        (q) => q['questionNo'] === number
      );

      thisQuestion.map((qu) => {
        setData({
          ...data,
          answer: qu.answer,
          collectOne: qu.collectOne,
          collectTwo: qu.collectTwo,
          answerOne: qu.answerOne,
          answerTwo: qu.answerTwo,
        });
      });
    }
  };

  useEffect(() => {
    updateAnswer();
  }, [data.collectOne, data.collectTwo]);

  useEffect(() => {
    updateAnswerFromLocal();
  }, []);

  const updateAnswer = () => {
    const { collectOne, collectTwo } = data;

    if (collectOne === 'yesOne' && collectTwo === 'yesTwo') {
      setData({
        ...data,
        answer: { [collectOpts[0].answer]: 'RECIPIENT_NAME: yes, P_NAME: yes' },
        step: collectOpts[0].step,
      });
    }

    if (collectOne === 'yesOne' && collectTwo === 'noTwo') {
      setData({
        ...data,
        answer: { [collectOpts[1].answer]: 'RECIPIENT_NAME: yes, P_NAME: no' },
        step: collectOpts[1].step,
      });
    }

    if (collectOne === 'noOne' && collectTwo === 'yesTwo') {
      setData({
        ...data,
        answer: { [collectOpts[2].answer]: 'RECIPIENT_NAME: no, P_NAME: yes' },
        step: collectOpts[2].step,
      });
    }

    if (collectOne === 'noOne' && collectTwo === 'noTwo') {
      setData({
        ...data,
        answer: { [collectOpts[3].answer]: 'RECIPIENT_NAME: no, P_NAME: no' },
        step: collectOpts[3].step,
      });
    }
  };

  return (
    <Flex direction="column">
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        description={description}
        infoModal={infoModal}
        infoModalContent={infoModalContent}
        errors={errors}
        errorMessage={errorMessage}
      />

      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        <Flex>
          <Flex direction="column">
            <Text as="h5" fontWeight="bold" mb={1}>
              {titleOne}
            </Text>
            <Flex>
              {optionsOne &&
                optionsOne.map((item, i) => {
                  return (
                    <Box
                      key={i}
                      bg="gray.100"
                      borderRadius="md"
                      alignItems="center"
                      mb={6}
                      mr={2}
                    >
                      <RadioInput
                        id={item.id}
                        name="radioOne"
                        onChange={handleChange}
                        step={item.step}
                        collect={item.collect}
                        value={item.label}
                        checked={data.answerOne}
                        label={item.label}
                        option={item.option}
                        required
                      />
                    </Box>
                  );
                })}
            </Flex>
          </Flex>
          <Flex direction="column" ml={{ base: 4, lg: 10 }}>
            <Text as="h5" fontWeight="bold" mb={1}>
              {titleTwo}
            </Text>
            <Flex>
              {optionsTwo &&
                optionsTwo.map((item, i) => {
                  return (
                    <Flex
                      key={i}
                      bg="gray.100"
                      borderRadius="md"
                      alignItems="center"
                      mb={6}
                      mr={2}
                    >
                      <RadioInput
                        id={item.id}
                        name="radioTwo"
                        onChange={handleChange}
                        step={item.step}
                        collect={item.collect}
                        value={item.label}
                        checked={data.answerTwo}
                        label={item.label}
                        option={item.option}
                        required
                      />
                    </Flex>
                  );
                })}
            </Flex>
          </Flex>
        </Flex>
        <Button type="submit" bg="brandGreen" bgHover="brandGreenHover" mt="6">
          Next
        </Button>
      </Box>
    </Flex>
  );
}
