import React, { useState, useEffect } from 'react';
import {
  Flex,
  Grid,
  Box,
  Select,
  VisuallyHidden,
  ControlBox,
  Icon,
} from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function DatePicker({ details }) {
  const { question, handleSubmit, errors } = details;
  const {
    id,
    number,
    answers,
    title,
    errorMessage,
    subTitle,
    description,
    infoModal,
    infoModalContent,
    dontKnowOptionLabel,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: '',
    answer: {},
    dd: '',
    mm: '',
    yyyy: '',
    disabled: false,
    question: emailQuestion,
  });

  const handleChange = ({ target }) => {
    const step = target.attributes['data-nextstep'].value;
    const collect = target.attributes['data-collect'].value;

    if (target.type === 'checkbox') {
      setData({
        ...data,
        step,
        collect,
        dd: '',
        mm: '',
        yyyy: '',
        disabled: !data.disabled,
      });
    } else {
      setData({
        ...data,
        [target.name]: target.value,
        step,
        collect,
      });
    }
  };

  useEffect(() => {
    const setAnswer = () => {
      const updatedAnswer = data.disabled
        ? "Don't know"
        : `${data.dd}/${data.mm}/${data.yyyy}`;

      setData({
        ...data,
        answer: { DOB: updatedAnswer },
      });
    };

    setAnswer();
  }, [data.dd, data.mm, data.yyyy, data.disabled]);

  const updateAnswerFromLocal = () => {
    const localQuestions = getFromLocalStorage('questions');
    if (localQuestions) {
      const thisQuestion = localQuestions.filter(
        (q) => q['questionNo'] === number
      );

      thisQuestion.map((qu) => {
        setData({
          ...data,
          answer: qu.answer,
          step: qu.step,
          dd: qu.dd,
          mm: qu.mm,
          yyyy: qu.yyyy,
          disabled: qu.disabled,
        });
      });
    }
  };

  useEffect(() => {
    updateAnswerFromLocal();
  }, []);

  const generateYears = () => {
    const max = new Date().getFullYear() - 19;
    const min = max - 81;
    let years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }

    return years;
  };

  return (
    <Flex direction="column">
      {errors && <h5 style={{ color: 'red' }}>{errorMessage}</h5>}
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        description={description}
        infoModal={infoModal}
        infoModalContent={infoModalContent}
      />

      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        {answers &&
          answers.map((item, i) => {
            return (
              <Box key={i}>
                {item.type === 'date' && (
                  <Grid
                    mt={4}
                    templateColumns={{
                      base: 'repeat(1, 1fr)',
                      lg: 'repeat(3, 1fr)',
                    }}
                    gap={{ base: 3, lg: 4 }}
                  >
                    <Select
                      placeholder="DD"
                      name="dd"
                      onChange={handleChange}
                      value={data.dd}
                      data-nextstep={item.step}
                      data-collect={item.collect}
                      disabled={data.disabled}
                      isRequired
                    >
                      {Array(31)
                        .fill()
                        .map((_, i) => {
                          return (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          );
                        })}
                    </Select>
                    <Select
                      placeholder="MM"
                      name="mm"
                      onChange={handleChange}
                      value={data.mm}
                      data-nextstep={item.step}
                      data-collect={item.collect}
                      disabled={data.disabled}
                      isRequired
                    >
                      {Array(12)
                        .fill()
                        .map((_, i) => {
                          return (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          );
                        })}
                    </Select>
                    <Select
                      placeholder="YYYY"
                      name="yyyy"
                      onChange={handleChange}
                      value={data.yyyy}
                      data-nextstep={item.step}
                      data-collect={item.collect}
                      disabled={data.disabled}
                      isRequired
                    >
                      {generateYears().map((y, i) => {
                        return (
                          <option key={i} value={y}>
                            {y}
                          </option>
                        );
                      })}
                    </Select>
                  </Grid>
                )}
                {item.type === 'checkbox' && (
                  <Grid mt={4}>
                    <label>
                      <VisuallyHidden
                        as="input"
                        id={i}
                        type={item.type}
                        name={item.collect}
                        onChange={handleChange}
                        data-collect={item.collect}
                        data-nextstep={item.step}
                        checked={data.disabled}
                        value=""
                      />

                      <ControlBox
                        borderWidth="2px"
                        size="16px"
                        rounded="sm"
                        _checked={{
                          bg: 'blue.500',
                          color: 'white',
                          borderColor: 'blue.500',
                        }}
                        _focus={{
                          borderColor: 'blue.600',
                          boxShadow: 'outline',
                        }}
                      >
                        <Icon name="check" size="12px" />
                      </ControlBox>

                      <Box as="span" verticalAlign="top" ml={2}>
                        {dontKnowOptionLabel}
                      </Box>
                    </label>
                  </Grid>
                )}
              </Box>
            );
          })}
        <Button type="submit" bg="brandGreen" bgHover="brandGreenHover" mt="6">
          Next
        </Button>
      </Box>
    </Flex>
  );
}
