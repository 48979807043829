import React from 'react';
import {
  Text,
  Box,
  Button,
  Flex,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  List,
  ListItem,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/core';

export default function QuestionHeader({
  title,
  subTitle,
  description,
  hintText,
  infoModal,
  infoModalContent,
  infoModalInsurance,
  errors,
  errorMessage,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent rounded="md">
          <ModalHeader bg="gray.100" roundedTop="md">
            <Flex alignItems="center">
              <Icon name="info" color="yellow.400" mr={2} />
              <Text color="yellow.400">Info</Text>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Box mt={4}>
              {infoModalInsurance === true ? (
                <Box>
                  <Text as="p">A mortgage has two parts:</Text>
                  <List styleType="disc" mt={2}>
                    <ListItem>Capital: the money you borrow</ListItem>
                    <ListItem>
                      Interest: the charge made by the lender on the amount you
                      owe
                    </ListItem>
                  </List>
                  <Text as="p" mt={2}>
                    If you get a repayment mortgage, each month you pay back the
                    capital and the interest together so the amount you owe
                    decreases every month until the end of your mortgage.
                  </Text>
                  <Text as="p">
                    With an interest-only mortgage, you initially only pay back
                    the interest on a monthly basis and repay the capital at the
                    end of the mortgage term. This means during the term of your
                    mortgage, whilst your monthly payments are lower, you will
                    still always owe the amount you borrowed.
                  </Text>
                </Box>
              ) : (
                <Text as="p">{infoModalContent}</Text>
              )}
            </Box>
          </ModalBody>

          <ModalFooter mt={8} borderTop="1px" borderTopColor="gray.100">
            <Button variantColor="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box mb={4}>
        {title && (
          <>
            <Flex>
              <Text
                as="h1"
                fontSize={{ base: '18px', lg: '24px' }}
                fontWeight="bold"
                dangerouslySetInnerHTML={{ __html: title }}
              ></Text>

              {infoModal && (
                <Button bg="transparent" onClick={onOpen}>
                  <Icon name="info" color="yellow.400" />
                </Button>
              )}
            </Flex>
          </>
        )}
        {subTitle && (
          <Text as="h2" fontSize="18px">
            {subTitle}
          </Text>
        )}
        {description && (
          <Text as="h2" fontSize="18px" mt={4}>
            {description}
          </Text>
        )}
        {hintText && (
          <Text as="span" fontSize="14px" mt={4} display="inline-block">
            {hintText}
          </Text>
        )}
        {errors && (
          <Alert status="error" mt={4} variant="left-accent">
            <AlertIcon />
            <AlertTitle mr={2}>{errorMessage}</AlertTitle>
          </Alert>
        )}
      </Box>
    </>
  );
}
