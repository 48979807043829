import React, { useState, useEffect } from 'react';
import { Flex, Box, Select, Text } from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function OccupationSwitcher({ details }) {
  const { question, handleSubmit, errors } = details;
  const {
    id,
    number,
    title,
    subTitle,
    description,
    errorMessage,
    optionsOne,
    optionsTwo,
    collectOpts,
    infoModal,
    infoModalContent,
    infoModalInsurance,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: '',
    answer: {},
    collectOne: '',
    collectTwo: '',
    answerOne: '',
    answerTwo: '',
    optionA: '',
    optionB: '',
    question: emailQuestion,
  });

  const handleChange = ({ target }) => {
    const option = target.options[target.selectedIndex].getAttribute(
      'data-option'
    );

    const collect = target.options[target.selectedIndex].getAttribute(
      'data-collect'
    );

    const label = target.options[target.selectedIndex].getAttribute(
      'data-label'
    );

    if (target.name === 'radioOne') {
      setData({
        ...data,
        collectOne: collect,
        answerOne: label,
        optionA: option,
      });
    }

    if (target.name === 'radioTwo') {
      setData({
        ...data,
        collectTwo: collect,
        answerTwo: label,
        optionB: option,
      });
    }
  };

  const updateAnswerFromLocal = () => {
    const localQuestions = getFromLocalStorage('questions');
    if (localQuestions) {
      const thisQuestion = localQuestions.filter(
        (q) => q['questionNo'] === number
      );

      thisQuestion.map((qu) => {
        setData({
          ...data,
          answer: qu.answer,
          collectOne: qu.collectOne,
          collectTwo: qu.collectTwo,
          answerOne: qu.answerOne,
          answerTwo: qu.answerTwo,
          optionA: qu.optionA,
          optionB: qu.optionB,
          step: qu.step,
        });
      });
    }
  };

  const updateAnswer = () => {
    if (data.optionA === 'a' && data.optionB === 'a') {
      setData({
        ...data,
        step: collectOpts[0].step,
        answer: {
          [`RECIPIENT_NAME`]: `RECIPIENT_NAME: ${data.answerOne}`,
          [`P_NAME`]: `P_NAME: ${data.answerTwo}`,
        },
      });
    }

    if (data.optionA === 'b' && data.optionB === 'b') {
      setData({
        ...data,
        step: collectOpts[1].step,
        answer: {
          [`RECIPIENT_NAME`]: `RECIPIENT_NAME: ${data.answerOne}`,
          [`P_NAME`]: `P_NAME: ${data.answerTwo}`,
        },
      });
    }

    if (data.optionA === 'a' && data.optionB === 'b') {
      setData({
        ...data,
        step: collectOpts[2].step,
        answer: {
          [`RECIPIENT_NAME`]: `RECIPIENT_NAME: ${data.answerOne}`,
          [`P_NAME`]: `P_NAME: ${data.answerTwo}`,
        },
      });
    }

    if (data.optionA === 'b' && data.optionB === 'a') {
      setData({
        ...data,
        step: collectOpts[3].step,
        answer: {
          [`RECIPIENT_NAME`]: `RECIPIENT_NAME: ${data.answerOne}`,
          [`P_NAME`]: `P_NAME: ${data.answerTwo}`,
        },
      });
    }
  };

  useEffect(() => {
    updateAnswer();
  }, [data.collectOne, data.collectTwo, data.optionA, data.optionB]);

  useEffect(() => {
    updateAnswerFromLocal();
  }, []);

  return (
    <Flex direction="column">
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        infoModal={infoModal}
        infoModalContent={infoModalContent}
        description={description}
        infoModalInsurance={infoModalInsurance}
        errors={errors}
        errorMessage={errorMessage}
      />

      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        <Flex mt={4}>
          <Box>
            <Text as="h5" fontWeight="bold" mb={1}>
              RECIPIENT_NAME
            </Text>
            <Select
              placeholder="Select option"
              w={{ base: '100%', lg: '300px' }}
              onChange={handleChange}
              value={data.answerOne}
              name="radioOne"
              isRequired
            >
              {optionsOne &&
                optionsOne.map((item, i) => {
                  return (
                    <option
                      key={i}
                      data-collect={item.collect}
                      data-label={item.label}
                      data-option={item.option}
                      value={data.label}
                    >
                      {item.label}
                    </option>
                  );
                })}
            </Select>
          </Box>
          <Box ml={6}>
            <Text as="h5" fontWeight="bold" mb={1}>
              P_NAME
            </Text>
            <Select
              placeholder="Select option"
              w={{ base: '100%', lg: '300px' }}
              onChange={handleChange}
              value={data.answerTwo}
              name="radioTwo"
              isRequired
            >
              {optionsOne &&
                optionsTwo.map((item, i) => {
                  return (
                    <option
                      key={i}
                      data-collect={item.collect}
                      data-label={item.label}
                      data-option={item.option}
                      value={item.label}
                      required
                    >
                      {item.label}
                    </option>
                  );
                })}
            </Select>
          </Box>
        </Flex>
        <Button type="submit" bg="brandGreen" bgHover="brandGreenHover" mt="6">
          Next
        </Button>
      </Box>
    </Flex>
  );
}
