import React, { useState, createRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Input,
  VisuallyHidden,
  ControlBox,
  Icon,
  Text,
} from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import QuestionHeader from '../partials/QuestionHeader';
import Button from '../partials/Button';

export default function FreeType({ details }) {
  const { question, handleSubmit } = details;
  const {
    id,
    number,
    answers,
    title,
    errorMessage,
    subTitle,
    description,
    dontKnowOptionLabel,
    infoModal,
    infoModalContent,
    emailQuestion,
    customValidation,
    minLength,
    maxLength,
    validationError,
    min,
    max,
    numericValidation,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: '',
    answer: {},
    disabled: false,
    inputAnswer: '',
    question: emailQuestion,
    errorMessage: '',
  });

  let inputRefs = [];

  const handleChange = ({ target }) => {
    const collect = target.attributes['data-collect'].value;
    const nextstep = target.attributes['data-nextstep'].value;

    if (target.type === 'checkbox') {
      setData({
        ...data,
        answer: { [collect]: collect },
        disabled: !data.disabled,
        inputAnswer: target.value,
        step: nextstep,
      });
    } else {
      setData({
        ...data,
        answer: { [collect]: target.value },
        inputAnswer: target.value,
        step: nextstep,
      });
    }
  };

  const updateAnswerFromLocal = () => {
    const localQuestions = getFromLocalStorage('questions');
    if (localQuestions) {
      const thisQuestion = localQuestions.filter(
        (q) => q['questionNo'] === number
      );

      thisQuestion.map((qu) => {
        setData({
          ...data,
          answer: qu.answer,
          step: qu.step,
          disabled: qu.disabled,
          inputAnswer: Object.values(qu.answer),
        });
      });
    }
  };

  useEffect(() => {
    updateAnswerFromLocal();
  }, []);

  const validate = ({ target }) => {
    const inputOne = document.querySelector('#inputOne');

    if (inputOne.value === '' && data.disabled === false) {
      setData({
        ...data,
        errors: true,
        errorMessage,
      });
      return;
    }

    if (customValidation) {
      if (inputOne.value === '0') {
        setData({
          ...data,
          errors: true,
          errorMessage: 'Please enter a value more than 0',
        });
        return;
      }

      if (inputOne.value.length < minLength && data.disabled === false) {
        setData({
          ...data,
          errors: true,
          errorMessage: validationError,
        });
        return;
      }

      if (inputOne.value < min || inputOne.value > max) {
        setData({
          ...data,
          errors: true,
          errorMessage:
            'You have entered an incorrect value. Please type in a number between 18 and 100.',
        });
        return;
      }
    }

    if (numericValidation) {
      if (!/^\d*$/.test(inputOne.value)) {
        setData({
          ...data,
          errors: true,
          errorMessage: 'You can only enter numeric values',
        });
        return;
      }
    }

    handleSubmit(data);
  };

  return (
    <Flex direction="column" w="100%">
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        description={description}
        infoModal={infoModal}
        infoModalContent={infoModalContent}
        errors={data.errors}
        errorMessage={data.errorMessage}
      />

      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          validate(e);
        }}
      >
        {answers &&
          answers.map((item, i) => {
            const newRef = createRef();
            inputRefs.push(newRef);

            return (
              <Box key={i} mt={4}>
                {item.type === 'text' && (
                  <Input
                    id="inputOne"
                    name={item.collect}
                    onChange={handleChange}
                    data-collect={item.collect}
                    data-nextstep={item.step}
                    ref={newRef}
                    type={item.type}
                    placeholder={item.label}
                    disabled={data.disabled}
                    value={data.inputAnswer || ''}
                    min={item.min}
                    max={item.max}
                    maxLength={customValidation && maxLength}
                  />
                )}
                {item.type === 'number' && (
                  <Input
                    id="inputOne"
                    name={item.collect}
                    onChange={handleChange}
                    data-collect={item.collect}
                    data-nextstep={item.step}
                    ref={newRef}
                    type={item.type}
                    placeholder={item.label}
                    disabled={data.disabled}
                    value={data.inputAnswer || ''}
                    max={item.max}
                    pattern="\d*"
                    maxLength={customValidation && maxLength}
                  />
                )}
                {item.type === 'tel' && (
                  <Input
                    id="inputOne"
                    name={item.collect}
                    onChange={handleChange}
                    data-collect={item.collect}
                    data-nextstep={item.step}
                    ref={newRef}
                    type={item.type}
                    placeholder={item.label}
                    disabled={data.disabled}
                    value={data.inputAnswer || ''}
                    max={item.min}
                    max={item.max}
                    pattern="\d*"
                    maxLength={customValidation && maxLength}
                  />
                )}
                {item.type === 'checkbox' && (
                  <Text as="label">
                    <VisuallyHidden
                      as="input"
                      id="checkOne"
                      type={item.type}
                      name={item.collect}
                      onChange={handleChange}
                      data-collect={item.collect}
                      data-nextstep={item.step}
                      ref={newRef}
                      checked={data.disabled}
                      value=""
                    />

                    <ControlBox
                      borderWidth="2px"
                      size="16px"
                      rounded="sm"
                      _checked={{
                        bg: 'blue.500',
                        color: 'white',
                        borderColor: 'blue.500',
                      }}
                      _focus={{
                        borderColor: 'blue.600',
                        boxShadow: 'outline',
                      }}
                    >
                      <Icon name="check" size="12px" />
                    </ControlBox>

                    <Box as="span" verticalAlign="top" ml={2}>
                      {dontKnowOptionLabel}
                    </Box>
                  </Text>
                )}
              </Box>
            );
          })}
        <Button type="submit" bg="brandGreen" bgHover="brandGreenHover" mt="6">
          Next
        </Button>
      </Box>
    </Flex>
  );
}
