import React, { useState, useEffect } from 'react';
import { Flex, Box, FormLabel, Switch, Text, Checkbox } from '@chakra-ui/core';

import { getFromLocalStorage } from '../../utils/functions';
import QuestionHeader from '../partials/QuestionHeader';
import CustomInput from '../inputs/CustomInput';
import Button from '../partials/Button';

export default function WeightSwitcherMulti({ details }) {
  const { question, handleSubmit, errors } = details;
  const {
    id,
    number,
    title,
    subTitle,
    description,
    errorMessage,
    step,
    emailQuestion,
  } = question;

  const [data, setData] = useState({
    id,
    questionNo: number,
    step: step,
    answer: {},
    st0: '',
    st1: '',
    llbs0: '',
    llbs1: '',
    kg0: '',
    kg1: '',
    unitSwitch: false,
    dontKnow: false,
    question: emailQuestion,
  });

  const handleChange = ({ target }) => {
    setData({
      ...data,
      [target.name]: target.value,
    });
  };

  const setAnswer = () => {
    let updatedAnswer;

    if (data.unitSwitch === true) {
      const valueSwitch = data.dontKnow
        ? `P_NAME: Don't Know`
        : `P_NAME: ${data.kg1}Kg`;

      updatedAnswer = {
        RecipientNameWeight: `RECIPIENT_NAME: ${data.kg0}Kg`,
        Pname1Weight: valueSwitch,
      };
    } else {
      const valueSwitch = data.dontKnow
        ? `P_NAME: Don't Know`
        : `P_NAME: ${data.st1}St / ${data.llbs1}Llbs`;

      updatedAnswer = {
        RecipientNameHeight: `RECIPIENT_NAME: ${data.st0}St / ${data.llbs0}Llbs`,
        Pname1Height: valueSwitch,
      };
    }

    setData({
      ...data,
      answer: updatedAnswer,
      unitSwitch: data.unitSwitch,
    });
  };

  useEffect(() => {
    setAnswer();
  }, [
    data.st0,
    data.st1,
    data.llbs0,
    data.llbs1,
    data.kg0,
    data.kg1,
    data.unitSwitch,
    data.dontKnow,
  ]);

  useEffect(() => {
    const updateAnswerFromLocal = () => {
      const localQuestions = getFromLocalStorage('questions');

      if (localQuestions) {
        const thisQuestion = localQuestions.filter(
          (q) => q['questionNo'] === number
        );

        thisQuestion.map((qu) => {
          setData({
            ...data,
            answer: qu.answer,
            st0: qu.st0,
            st1: qu.st1,
            llbs0: qu.llbs0,
            llbs1: qu.llbs1,
            kg0: qu.kg0,
            kg1: qu.kg1,
            unitSwitch: qu.unitSwitch,
            dontKnow: qu.dontKnow,
          });
        });
      }
    };
    updateAnswerFromLocal();
  }, []);

  const toggleUnits = () => {
    setData({
      ...data,
      unitSwitch: !data.unitSwitch,
    });
  };

  const toggleDontKnow = () => {
    setData({
      ...data,
      st1: '',
      llbs1: '',
      kg1: '',
      dontKnow: !data.dontKnow,
    });
  };

  return (
    <Flex direction="column">
      {errors && <h5 style={{ color: 'red' }}>{errorMessage}</h5>}
      <QuestionHeader
        title={title}
        subTitle={subTitle}
        description={description}
      />
      <Box
        as="form"
        mt={4}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        <Flex direction="column">
          <Flex mb={1}>
            <Text as="h5" fontWeight="bold">
              RECIPIENT_NAME
            </Text>
          </Flex>
          {data.unitSwitch === false ? (
            <Flex direction="column">
              <Flex>
                <CustomInput
                  name="st0"
                  label="st"
                  max="50"
                  min="5"
                  value={data.st0}
                  handleChange={handleChange}
                  mr="4"
                  mb="2"
                  required="required"
                  required={data.unitSwitch ? '' : 'required'}
                />
                <CustomInput
                  name="llbs0"
                  label="llbs"
                  min="0"
                  max="13"
                  value={data.llbs0}
                  handleChange={handleChange}
                  mb="2"
                  required="required"
                  required={data.unitSwitch ? '' : 'required'}
                />
              </Flex>
            </Flex>
          ) : (
            <Flex direction="column">
              <CustomInput
                name="kg0"
                label="kg"
                min="31"
                max="318"
                value={data.kg0}
                handleChange={handleChange}
                mb="2"
                required={data.unitSwitch ? 'required' : ''}
              />
            </Flex>
          )}
        </Flex>
        <Flex direction="column" mt={4}>
          <Flex mb={1}>
            <Text as="h5" fontWeight="bold">
              P_NAME
            </Text>
            <Checkbox
              onChange={toggleDontKnow}
              ml={2}
              isChecked={data.dontKnow}
            >
              Don't know
            </Checkbox>
          </Flex>
          {data.unitSwitch === false ? (
            <Flex direction="column">
              <Flex>
                <CustomInput
                  name="st1"
                  label="st"
                  min="5"
                  max="50"
                  value={data.st1}
                  handleChange={handleChange}
                  mr="4"
                  mb="2"
                  required={data.unitSwitch ? '' : 'required'}
                  disabled={data.dontKnow}
                />
                <CustomInput
                  name="llbs1"
                  label="llbs"
                  min="0"
                  max="13"
                  value={data.llbs1}
                  handleChange={handleChange}
                  mb="2"
                  required={data.unitSwitch ? '' : 'required'}
                  disabled={data.dontKnow}
                />
              </Flex>
            </Flex>
          ) : (
            <Flex direction="column">
              <CustomInput
                name="kg1"
                label="kg"
                min="31"
                max="318"
                mb="2"
                value={data.kg1}
                handleChange={handleChange}
                required={data.unitSwitch ? 'required' : ''}
                disabled={data.dontKnow}
              />
            </Flex>
          )}
        </Flex>
        <Flex direction="column" mt={4}>
          <Flex align="center">
            <Switch
              id="toggle-unit"
              mr={2}
              onChange={toggleUnits}
              isChecked={data.unitSwitch}
            />
            <FormLabel htmlFor="toggle-unit">
              Change unit to
              {data.unitSwitch ? ' St/Llbs' : ' Kg'}
            </FormLabel>
          </Flex>

          <Box mt={6} alignSelf="flex-start">
            <Button type="submit" bg="brandGreen" bgHover="brandGreenHover">
              Next
            </Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}
