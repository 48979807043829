import React from 'react';
import { InputGroup, Input, InputRightAddon } from '@chakra-ui/core';

import { capitalizeFirstLetter } from '../../utils/functions';

export default function CustomInput({
  mr,
  mb,
  w,
  name,
  max,
  min,
  value,
  handleChange,
  label,
  required,
  disabled,
}) {
  return (
    <InputGroup mr={mr} mb={mb} isRequired={required}>
      <Input
        type="number"
        name={name}
        min={min}
        max={max}
        roundedRight="0"
        onChange={handleChange}
        value={value}
        w={w}
        isRequired={required}
        disabled={disabled}
      />
      <InputRightAddon children={capitalizeFirstLetter(label)} />
    </InputGroup>
  );
}
